import React, { Fragment } from "react";

export default function IconSpock() {
  return (
    <Fragment>
      <svg xmlns={"http://www.w3.org/2000/svg"} width={"45"} height={"59"}>
        <path
          fill={"#3B4262"}
          d={
            "M44.042 32.422l-.863-.86c-2.062-2.054-5.433-2.5-7.95-1.06l-5.376 3.059 1.12-24.502c0-2.054-1.678-3.726-3.743-3.726-.446 0-.875.079-1.273.222l.134-1.315c0-2.077-1.705-3.767-3.798-3.767-1.936 0-3.559 1.445-3.764 3.299l-3.45 20.75c-.045.282-.536.253-.588.075L10.416 7.962a3.658 3.658 0 00-3.502-2.629c-1.118 0-2.157.501-2.853 1.375a3.592 3.592 0 00-.69 3.08l.792 3.35a3.34 3.34 0 00-1.335.168 3.447 3.447 0 00-2.326 3.818L2.9 30.85c0 5.415.953 9.423 1.754 11.83a13.61 13.61 0 01.687 4.291c0 1.284-.179 2.562-.534 3.796l-1.86 6.482c-.104.366-.03.76.198 1.065.232.304.592.483.975.483h21.97a1.218 1.218 0 001.16-1.6c-.013-.031-1.033-3.169-.067-7.437 1.225-.99 5.514-4.462 7.054-5.862 2.546-2.315 9.521-9.468 9.817-9.772a1.21 1.21 0 00-.012-1.705z"
          }
        />
      </svg>
    </Fragment>
  );
}
